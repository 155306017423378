import styled from 'styled-components'

export const Video = styled.section`
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0 15px;
  width: 100%;

  .MuiContainer-root {
    align-items: flex-start;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    height: 100%;
    padding: 0;
    gap: 20px;

    @media (min-width: 992px) {
      flex-direction: row;
      justify-content: space-between;
      gap: 75px;
    }
  }
`
export const VideoThumb = styled.div`
  cursor: pointer;

  @media (max-width: 991px) {
    margin: 0 auto;
  }

  @media (min-width: 992px) {
    width: 546px;
    flex-shrink: 0;
  }
`

export const VideoText = styled.div`
  flex-shrink: 1;
  flex-grow: 0;

  @media (min-width: 992px) {
    text-align: left;
  }

  h2 {
    font-size: clamp(24px, 5vw, 42px);
    color: var(--primary-color);
    font-family: var(--fontRegularItalic);

    @media (min-width: 992px) {
      line-height: 42px;
    }
  }

  h3 {
    font-size: clamp(20px, 4vw, 24px);
    color: var(--primary-color);
    font-family: var(--fontRegularItalic);
    margin: 10px 0 15px;
    font-weight: normal;

    @media (min-width: 768px) {
      line-height: 30px;
    }
  }

  p {
    margin-bottom: 15px;
    font-size: clamp(15px, 3vw, 18px);
    color: var(--primary-color);
    font-family: var(--fontRegularItalic);
  }
`

export const ButtonComprar = styled.a`
  background-color: var(--primary-color);
  border-radius: 99999px;
  border: 2px solid var(--primary-color);
  color: #fff;
  display: block;
  font-family: 'ASICSFont3.0-Bold';
  font-size: 16px;
  margin: 10px 0;
  padding: 10px 0;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.3s ease;
  width: 265px;

  @media (max-width: 992px) {
    margin: 10px auto;
  }

  &:hover {
    background-color: transparent;
    color: var(--primary-color);
  }
`
