import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import Container from '@material-ui/core/Container'
import Picture from '../Picture'

import { GlobalContext } from '../../Context/global'

import * as S from './styles'

const VideoWithModal = ({ classname, thumb }) => {
  const { handleModalVideoOpen } = useContext(GlobalContext)

  return (
    <S.Video className={classname}>
      <Container>
        <S.VideoThumb className="video-thumb" onClick={handleModalVideoOpen}>
          <Picture
            xs={thumb}
            md={thumb}
            lg={thumb}
            alt="Clique para assistir ao vídeo"
          />
        </S.VideoThumb>

        <S.VideoText className="video-text">
          <h2>Estamos todos aprendendo, o tempo todo</h2>
          <h3>
            Reforçando a importância de pensar em sustentabilidade como parte da
            rotina, a ASICS hospedou um grupo de corredores em um retiro em
            IslandWood na Ilha Bainbridge, Washington, para uma vivência mais
            reflexiva.
          </h3>
          <p>
            <strong>Nome:</strong> MaryLu Pulido
          </p>
          <p>
            <strong>Idade:</strong> 27
          </p>
          <p>
            <strong>Cidade:</strong> Whittier, Califórnia
          </p>
          <p>
            <strong>Corre há quanto tempo:</strong> Desde que me entendo por
            gente, mas em competição por mais de 10 anos (aproximadamente
            12/13).
          </p>
          <p>
            <strong>O que a sustentabilidade significa pra mim:</strong>{' '}
            Sustentabilidade, pra mim, significa sempre dar de volta o que você
            tira.
          </p>
        </S.VideoText>
      </Container>
    </S.Video>
  )
}

VideoWithModal.propTypes = {
  classname: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  thumb: PropTypes.string.isRequired,
}

export default VideoWithModal
