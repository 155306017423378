import React, { useEffect, useRef } from 'react'
import Container from '@material-ui/core/Container'

import SEO from '../../components/SEO'
import Layout from '../../components/Layout'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper'
import VideoModal from '../../components/ModalVideo'
import VideoWithModalSustainability from '../../components/VideoWithModalSustainability'

import { ditoTrack } from '../../helpers/dito'

import {
  bannerNatureza,
  carrossel01,
  carrossel02,
  carrossel03,
  carrosselProduto01,
  carrosselProduto02,
  circularidade,
  iconeTimeline01,
  iconeTimeline02,
  iconeTimeline03,
  iconeTimeline04,
  produto,
  sol01,
  sol02,
  sol03,
  sol04,
  sol05,
  videoheader,
  videoThumb,
} from '../../images/sustainability'
import * as S from './styles'
import { graphql } from 'gatsby'

SwiperCore.use([Navigation, Pagination, Autoplay])

const SustainabilityPage = () => {
  const videoEl = useRef(null)
  const animateSliderEl01 = useRef(null)
  const animateSliderEl02 = useRef(null)
  const animateSliderEl03 = useRef(null)
  const animateSliderEl04 = useRef(null)

  const videoHeader = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch((error) => {
        console.error('Error attempting to play', error)
      })
  }

  const animateSlider1 = () => {
    animateSliderEl01 &&
      animateSliderEl01.current &&
      animateSliderEl01.current.play().catch((error) => {
        console.error('Error attempting to play', error)
      })
  }
  const animateSlider2 = () => {
    animateSliderEl02 &&
      animateSliderEl02.current &&
      animateSliderEl02.current.play().catch((error) => {
        console.error('Error attempting to play', error)
      })
  }

  const animateSlider3 = () => {
    animateSliderEl03 &&
      animateSliderEl03.current &&
      animateSliderEl03.current.play().catch((error) => {
        console.error('Error attempting to play', error)
      })
  }

  const animateSlider4 = () => {
    animateSliderEl04 &&
      animateSliderEl04.current &&
      animateSliderEl04.current.play().catch((error) => {
        console.error('Error attempting to play', error)
      })
  }

  useEffect(() => {
    ditoTrack('acessou-sustainability')
    videoHeader()
    animateSlider1()
    animateSlider2()
    animateSlider3()
    animateSlider4()
  }, [])

  return (
    <Layout>
      <SEO title="Sustainability" />

      <VideoModal videoId="-WHR4v7AoJI" />
      <S.VideoHeader>
        <h2>Na direção de um mundo melhor</h2>
        <video
          style={{ maxWidth: '100%', width: '100%', margin: '0 auto' }}
          playsInline
          loop
          muted
          alt="Sustainability"
          src={videoheader}
          ref={videoEl}
        />
      </S.VideoHeader>

      <S.SecaoTopo>
        <S.AssetEfeitoBg01 src={sol01} alt="" />
        <S.AssetEfeitoBg02 src={sol02} alt="" />

        <Container>
          <div className="textoTopo">
            <img src={sol03} alt="" />
            <h1>Só temos uma Terra para correr.</h1>
            <p>
              Desde a nossa fundação em 1949, nossa proposta tem sido ajudar as
              pessoas alcançar o equilíbrio entre mente sã e corpo são. Mas para
              isso, precisamos que a Terra também esteja sã para seguirmos nessa
              constante evolução. Por isso, o nosso trabalho envolve uma
              proposta sustentável, reduzindo a emissão de CO2, contando com
              materiais reciclados e usando menos recursos, como forma de trazer
              mais benefícios para o mundo em que amamos correr.
            </p>
          </div>

          <div className="timeline">
            <h2>Alvos de melhoria</h2>
            <div className="timeline__holder">
              <div className="timeline__item timeline__item--01">
                <span className="ano">2030</span>
                <div className="imagem">
                  <img src={iconeTimeline01} alt="" />
                </div>
                <p className="texto">Compromisso de poliéster 100% reciclado</p>
              </div>
              <div className="timeline__item timeline__item--02">
                <span className="ano">2030</span>
                <div className="imagem">
                  <img src={iconeTimeline02} alt="" />
                </div>
                <p className="texto">
                  Energia 100% renovável através das instalações de negócios
                </p>
              </div>
              <div className="timeline__item timeline__item--03">
                <span className="ano">2030</span>
                <div className="imagem">
                  <img src={iconeTimeline03} alt="" />
                </div>
                <p className="texto">
                  63% de redução de CO2 através das operações diretas e cadeia
                  de suprimentos vs. 2015
                </p>
              </div>
              <div className="timeline__item timeline__item--04">
                <span className="ano">2050</span>
                <div className="imagem">
                  <img src={iconeTimeline04} alt="" />
                </div>
                <p className="texto">
                  Emissões de
                  <br /> carbono Net-zero
                </p>
              </div>
            </div>
          </div>
        </Container>
      </S.SecaoTopo>

      <S.SecaoVideo>
        <VideoWithModalSustainability
          classname="sustainability"
          title=""
          subtitle=""
          thumb={videoThumb}
        />
      </S.SecaoVideo>

      <S.SecaoBanner>
        <Container>
          <h2>
            “Correndo na natureza eu me sinto exatamente onde eu devo estar.”
          </h2>
          <img src={bannerNatureza} alt="Uma mulher fazendo yoga" />
        </Container>
      </S.SecaoBanner>

      <S.SecaoProduto>
        <S.AssetEfeitoBg03 src={sol04} alt="" />
        <S.AssetEfeitoBg04 src={sol05} alt="" />

        <Container>
          <div className="conteudo conteudo__produto">
            <div className="conteudo__bloco esquerda">
              <h2>Plantando as sementes de um futuro mais brilhante.</h2>
              <p>
                O ícone do girassol da ASICS representa o nosso compromisso em
                preservar a Terra para futuras gerações, permitindo que cresçam
                mais saudáveis e positivas em mente e corpo. Conheça os produtos
                ASICS com elementos sustentáveis.
              </p>
              <S.ButtonComprar
                href="https://www.asics.com.br/calcados?filtro_101=sustentavel&utm_source=lab&utm_medium=lp&utm_campaign=crm-sustentabilidade"
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                VEJA NOSSOS MODELOS SUSTENTÁVEIS
              </S.ButtonComprar>
            </div>
            <div className="conteudo__bloco direita">
              <img src={produto} alt="Glideride 2" />
            </div>
          </div>

          <div className="conteudo conteudo__circularidade">
            <div className="conteudo__bloco esquerda">
              <img src={circularidade} alt="Circularidade" />
            </div>
            <div className="conteudo__bloco direita">
              <h2>Circularidade significa voltar de onde partiu.</h2>
              <p>
                A ASICS aposta em uma abordagem de negócios circular para
                atingir a mudança climática, com o objetivo de usar menos
                materiais e fazer produtos mais duráveis, de forma mais limpa e
                reciclável.
              </p>
              <p className="smaller">
                <strong>Design</strong> – criamos itens que usam menos recursos
                e são mais fáceis de reciclar.
              </p>
              <p className="smaller">
                <strong>Materiais</strong> – utilizamos opções recicladas, de
                origem biológica e mais eficientes no uso da água.
              </p>
              <p className="smaller">
                <strong>Produção</strong> – produzimos com energia renovável de
                forma eficiente e limpa.
              </p>
              <p className="smaller">
                <strong>Uso</strong> – melhoramos a qualidade constantemente
                para que os produtos durem ainda mais.
              </p>
              <p className="smaller">
                <strong>Fim de vida</strong> – recolhemos itens de volta para
                reuso e reciclagem.
              </p>
            </div>
          </div>
        </Container>
      </S.SecaoProduto>

      <S.CarrosselCalcados>
        <Container>
          <div className="topo">
            <h2>Investimos em nossos calçados, investimos no mundo.</h2>
            <p>
              Nos esforçamos constantemente para sermos mais gentis com o
              planeta, usando uma variedade de materiais mais sustentáveis.
            </p>
          </div>
          <Swiper
            slidesPerView={1}
            navigation
            pagination={{ clickable: true }}
            loop={false}
            spaceBetween={20}
          >
            <SwiperSlide>
              <div className="carrossel-item">
                <div className="carrossel-conteudo">
                  <div className="imagem">
                    <video
                      style={{
                        height: '450px',
                      }}
                      playsInline
                      loop
                      muted
                      alt="Sustainability"
                      src={carrossel03}
                      ref={animateSliderEl01}
                    />
                  </div>
                  <div className="produto">
                    <div className="produto__imagem">
                      <img src={carrosselProduto01} alt="" />
                    </div>
                    <h3>
                      <a
                        href="https://www.asics.com.br/Calcados?filtro_87=GEL-Kayano-Lite-2&utm_source=lab&utm_medium=lp&utm_campaign=crm-sustentabilidade"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Poliéster reciclado de garrafas PET
                      </a>
                    </h3>
                    <p>
                      A produção de poliéster de garrafas PET recicladas emite
                      menos dióxido de carbono e usa menos recursos fósseis.
                    </p>
                    <p className="smaller">
                      Nossos tênis GEL-KAYANO™ Lite 2 contam <br />
                      com poliéster reciclado de garrafas PET.
                    </p>
                  </div>
                </div>
                <p className="aviso">
                  *Essas imagens são interpretações artísticas de materiais
                  reciclados ou sustentáveis. Elas podem não ser representações
                  verdadeiras dos produtos ou materiais usados pela ASICS.
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="carrossel-item">
                <div className="carrossel-conteudo">
                  <div className="produto">
                    <div className="produto__imagem">
                      <img src={carrosselProduto02} alt="" />
                    </div>
                    <p className="custom-font-slide2">
                      Quando o assunto é visual de corrida e práticas
                      sustentáveis, o GEL-NIMBUS™ LITE 3 é o tênis ideal. Pois
                      além de mesh com materiais sustentáveis, o FLYTEFOAM™ na
                      entressola é feito de derivados de materiais orgânicos.{' '}
                      <a
                        href="https://www.asics.com.br/Calcados?filtro_87=GEL-Nimbus-Lite-3&utm_source=lab&utm_medium=lp&utm_campaign=crm-sustentabilidade"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Saiba mais
                      </a>
                    </p>
                  </div>
                  <div className="imagem">
                    <video
                      style={{
                        height: '450px',
                        transform: 'scaleX(-1)',
                      }}
                      playsInline
                      loop
                      muted
                      alt="Sustainability"
                      src={carrossel02}
                      ref={animateSliderEl02}
                    />
                  </div>
                </div>
                <p className="aviso">
                  *Essas imagens são interpretações artísticas de materiais
                  reciclados ou sustentáveis. Elas podem não ser representações
                  verdadeiras dos produtos ou materiais usados pela ASICS.
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="carrossel-item">
                <div className="carrossel-conteudo">
                  <div className="imagem">
                    <video
                      style={{
                        height: '450px',
                      }}
                      playsInline
                      loop
                      muted
                      alt="Sustainability"
                      src={carrossel01}
                      ref={animateSliderEl03}
                    />
                  </div>
                  <div className="produto">
                    <p className="custom-font-slide3">
                      Conheça mais sobre o compromisso da ASICS com a
                      sustentabilidade em nosso blog.
                    </p>
                    <S.ButtonComprar
                      href="https://lab.asics.com.br/blog/compromisso-da-asics-com-a-sustentabilidade.html?utm_source=lab&utm_medium=lp&utm_campaign=crm-sustentabilidade"
                      target="_blank"
                      rel="noopener noreferrer nofollow"
                    >
                      SAIBA MAIS
                    </S.ButtonComprar>
                  </div>
                </div>
                <p className="aviso">
                  *Essas imagens são interpretações artísticas de materiais
                  reciclados ou sustentáveis. Elas podem não ser representações
                  verdadeiras dos produtos ou materiais usados pela ASICS.
                </p>
              </div>
            </SwiperSlide>
          </Swiper>
        </Container>
      </S.CarrosselCalcados>
    </Layout>
  )
}

export default SustainabilityPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
