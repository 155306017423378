import styled from 'styled-components'

import { chevron, bgProduto } from '../../images/sustainability'

export const VideoHeader = styled.div`
  position: relative;
  overflow-y: hidden;
  border-radius: 0 0 0.5rem 0.5rem;
  h2 {
    color: #fff;
    font-size: 3.5rem;
    font-weight: bold;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 80px;
    text-align: center;
    font-family: var(--fontRegularItalic);
    line-height: 57px;

    @media (min-width: 768px) {
      line-height: normal;
      line-height: 25px;
    }

    @media (min-width: 1200px) {
      top: 50%;
      transform: translateY(-50%);
      line-height: normal;
      margin: 0;
    }
  }
  video {
    width: 100%;
    height: 400px;
    object-fit: cover;
    object-position: center;
  }
`

export const SecaoTopo = styled.section`
  padding: 30px 0;
  position: relative;

  .MuiContainer-root {
    @media (min-width: 1200px) {
      max-width: 1170px !important;
    }
  }

  @media (min-width: 992px) {
    padding: 60px 0 80px;
  }

  .textoTopo {
    text-align: center;

    h1 {
      color: var(--primary-color);
      font-family: var(--fontRegularItalic);
      font-size: clamp(24px, 5vw, 48px);
      margin: 10px 0;
      @media (min-width: 991px) and (max-width: 1366px) {
        font-size: clamp(24px, 5vw, 48px);
        font-weight: 400;
      }
    }

    p {
      color: var(--primary-color);
      font-family: var(--fontRegularItalic);
      font-size: clamp(20px, 3vw, 30px);

      @media (min-width: 992px) {
        line-height: 35px;
      }

      @media (min-width: 992px) and (max-width: 1366px) {
        font-size: clamp(20px, 3vw, 24px);
      }
    }
  }

  .timeline {
    width: 100%;
    margin: 30px auto;
    max-width: 990px;

    @media (min-width: 992px) {
      margin-top: 80px;
    }

    h2 {
      color: var(--primary-color);
      font-family: var(--fontRegularItalic);
      font-size: clamp(28px, 4vw, 36px);
      text-align: center;
      margin-bottom: 20px;

      @media (min-width: 992px) {
        margin-bottom: 40px;
      }
    }

    &__holder {
      display: flex;

      @media (max-width: 767px) {
        flex-flow: column;
        gap: 50px;
      }
    }

    &__item {
      flex-grow: 1;
      position: relative;
      margin-bottom: 50px;

      @media (max-width: 767px) {
        display: flex;
        flex-flow: column;
        gap: 10px;

        &:not(:last-child) {
          &::after {
            content: '';
            font-size: 20px;
            color: #fba800;
            width: 14px;
            height: 21px;
            background-image: url(${chevron});
            background-repeat: no-repeat;
            background-position: center top;
            position: absolute;
            bottom: -40px;
            left: 49%;
            transform: rotate(90deg) translateX(-50%);
          }
        }

        br {
          display: none;
        }
      }

      @media (min-width: 768px) {
        &:not(:last-child) {
          .ano {
            &::after {
              content: '';
              font-size: 20px;
              color: #fba800;
              width: 14px;
              height: 21px;
              background-image: url(${chevron});
              background-repeat: no-repeat;
              background-position: center top;
              position: absolute;
              top: 0;
              right: 0;
            }
          }
        }
      }

      &--01 {
        @media (min-width: 992px) {
          width: 210px;
        }
      }

      &--02 {
        @media (min-width: 992px) {
          width: 290px;
        }
      }

      &--03 {
        @media (min-width: 992px) {
          width: 270px;
        }
      }

      .ano {
        display: block;
        text-align: center;
        width: 100%;
        color: var(--primary-color);
        font-family: var(--fontBold);
        font-size: 20px;
        margin-bottom: 10px;
        border-bottom: 4px solid var(--primary-color);
        position: relative;

        @media (max-width: 767px) {
          width: 100px;
          margin: 0 auto;
        }
      }

      .imagem {
        text-align: center;

        @media (min-width: 768px) {
          margin-bottom: 15px;
          margin-top: 30px;
        }
      }

      .texto {
        color: var(--primary-color);
        font-family: var(--fontRegularItalic);
        font-size: 20px;
        text-align: center;
        padding: 0 5px;
      }
    }
  }
`

export const AssetEfeitoBg01 = styled.img`
  @media (max-width: 1919px) {
    display: none;
  }

  @media (min-width: 1920px) {
    position: absolute;
    top: -130px;
    left: 0;
  }
`

export const AssetEfeitoBg02 = styled.img`
  @media (max-width: 1919px) {
    display: none;
  }

  @media (min-width: 1920px) {
    position: absolute;
    bottom: 0;
    right: 0;
  }
`

export const SecaoVideo = styled.div`
  background: rgb(28, 134, 200);
  background: linear-gradient(
    90deg,
    rgba(28, 134, 200, 1) 0%,
    rgba(255, 236, 220, 1) 69%
  );
  padding: 40px 0;

  @media (min-width: 992px) {
    padding: 70px 0;
    .MuiContainer-root {
      align-items: center;
    }
  }
`

export const SecaoBanner = styled.div`
  padding: 90px 0;
  text-align: center;

  h2 {
    color: var(--primary-color);
    font-family: var(--fontRegularItalic);
    font-size: clamp(26px, 4vw, 32px);
    margin-bottom: 20px;

    @media (min-width: 768px) {
      font-size: clamp(40px, 4vw, 40px);
      margin-bottom: 40px;
    }

    @media (min-width: 768px) and (max-width: 1366px) {
      font-size: clamp(37px, 4vw, 25px);
    }
  }

  img {
    width: 100%;
    max-width: 1166px;
    display: block;
  }
`

export const SecaoProduto = styled.div`
  background: url(${bgProduto}) center top no-repeat;
  background-size: cover;
  padding: 50px 0;
  display: flex;
  position: relative;
  overflow: hidden;

  .conteudo {
    display: flex;
    align-items: flex-start;

    img {
      max-width: 100%;
    }

    @media (max-width: 1199px) {
      flex-flow: column;
      justify-content: center;
      gap: 20px;
    }

    @media (min-width: 992px) {
      justify-content: space-between;
    }

    &__bloco {
      width: 100%;
      flex-shrink: 0;

      &.esquerda {
        @media (min-width: 1200px) {
          width: 550px;
        }
      }

      &.direita {
        @media (min-width: 1200px) {
          width: 540px;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
    }

    &__circularidade {
      margin-top: 30px;
      img {
        @media (min-width: 990px) {
          transform: scale(1.7);
          margin-top: 80px;
        }
      }
      .direita {
        flex-shrink: 1;
        width: auto;
      }

      @media (min-width: 992px) {
        margin-top: 145px;
        gap: 70px;
      }
    }
  }

  h2 {
    font-size: clamp(27px, 5vw, 20px);
    color: var(--primary-color);
    font-family: var(--fontRegularItalic);
    font-weight: normal;
    margin-bottom: 30px;

    @media (min-width: 990px) and (max-width: 1366px) {
      line-height: 47px;
      font-size: clamp(26px, 5vw, 44px);
      text-align: left;
    }

    @media (min-width: 1367px) {
      font-size: clamp(26px, 5vw, 48px);
    }
  }

  p {
    font-size: clamp(18px, 4vw, 25px);
    color: var(--primary-color);
    font-family: var(--fontRegularItalic);
    margin-bottom: 30px;
    @media (min-width: 992px) and (max-width: 1366px) {
      font-size: clamp(18px, 4vw, 19px);
    }

    &.smaller {
      font-size: clamp(15px, 3vw, 18px);
      line-height: normal;
      margin-bottom: 20px;
    }
  }
`

export const AssetEfeitoBg03 = styled.img`
  @media (max-width: 1919px) {
    display: none;
  }

  @media (min-width: 1920px) {
    position: absolute;
    top: 60px;
    left: 0;
  }
`

export const AssetEfeitoBg04 = styled.img`
  @media (max-width: 1919px) {
    display: none;
  }

  @media (min-width: 1920px) {
    position: absolute;
    bottom: 130px;
    right: 0;
  }
`

export const ButtonComprar = styled.a`
  background-color: var(--primary-color);
  border-radius: 99999px;
  border: 2px solid var(--primary-color);
  color: #fff;
  display: block;
  font-family: 'ASICSFont3.0-Bold';
  font-size: 19px;
  margin: 10px 0;
  padding: 10px 0;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.3s ease;
  width: 100%;

  @media (min-width: 768px) {
    width: 450px;
  }

  @media (max-width: 1199px) {
    margin: 10px auto;
  }

  &:hover {
    background-color: transparent;
    color: var(--primary-color);
  }
`

export const CarrosselCalcados = styled.div`
  padding: 80px 0;

  .topo {
    text-align: center;

    h2 {
      font-size: clamp(26px, 5vw, 48px);
      color: var(--primary-color);
      font-family: var(--fontRegularItalic);
      line-height: 50px;
      margin-bottom: 30px;
      @media (min-width: 992px) and (max-width: 1366px) {
        font-size: clamp(26px, 4vw, 40px);
        margin-bottom: 15px;
      }
    }

    p {
      font-size: clamp(18px, 4vw, 25px);
      color: var(--primary-color);
      font-family: var(--fontRegularItalic);
      margin-bottom: 30px;
      line-height: 32px;
      @media (min-width: 992px) and (max-width: 1366px) {
        font-size: clamp(18px, 4vw, 21px);
      }
    }
  }

  .carrossel-item {
    .carrossel-conteudo {
      display: flex;
      align-items: center;
      gap: 40px;

      @media (max-width: 991px) {
        flex-flow: column;
        text-align: center;
        br {
          display: none;
        }
      }

      @media (min-width: 768px) {
        > div {
          width: 50%;
        }
      }
    }

    .aviso {
      font-size: 12px;
      color: var(--primary-color);
      font-family: var(--fontRegularItalic);
      text-align: center;
      margin: 20px 0;
    }

    .imagem {
      display: none;
      @media (min-width: 768px) {
        display: flex;
        video {
          object-fit: cover;
          object-position: center;
          @media (min-width: 991px) {
            width: 445px;
          }
        }
      }
    }

    .produto {
      &__imagem {
        text-align: center;
        margin-bottom: 30px;
        @media (min-width: 991px) and (max-width: 1366px) {
          img {
            max-width: 290px;
          }
        }
      }

      h3 {
        font-size: 32px;
        color: var(--primary-color);
        font-family: var(--fontRegularItalic);
        margin-bottom: 20px;
        a {
          color: var(--primary-color);
          text-decoration: none;
          &:hover {
            color: #fba800;
          }
        }
      }

      p {
        font-size: 22px;
        color: var(--primary-color);
        font-family: var(--fontRegularItalic);

        @media (min-width: 991px) and (max-width: 1366px) {
          max-width: 430px;
        }

        &.smaller {
          color: #b9411d;
          font-size: 18px;
          margin-top: 20px;
        }

        &.custom-font-slide2 {
          @media (min-width: 991px) and (max-width: 1366px) {
            width: 365px;
            margin: 0 auto;
            margin-top: -80px;
            font-size: 18px;
          }
        }

        &.custom-font-slide3 {
          @media (min-width: 991px) and (max-width: 1366px) {
            font-size: 34px;
          }
        }
      }
    }
  }

  .swiper-pagination {
    bottom: 0px;
  }
`
